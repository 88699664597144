<template>
  <a-spin :spinning="spinning">
    <div class="sign-method">
      <div class="t-w">
        <step :s="2" />
      </div>

      <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :actions-type="actionsType"
        :hide-row-selection="true"
        :hide-pagination="true"
        :columns="columns"
        :min-height="100"
      >
        <!-- <a-row>

        </a-row> -->
        <div class="t-head">
          <span>签署设置</span>
          <span>
            <a-button
              size="default"
              :type="'primary'"
              @click="createSignType(null)"
            >
              {{ l("添加签署方式") }}
            </a-button>
          </span>
        </div>
      </react-table>

      <div class="b-w">
        <a-button size="default" @click="goBack" style="margin-right: 10px">{{
          l("上一步")
        }}</a-button>
        <a-button
          size="default"
          type="primary"
          @click="_agreementTemplateAsyncElectronicSignService"
        >
          {{ l("下一步") }}
        </a-button>
      </div>

      <a-modal
        centered
        :title="inputModalParams.title"
        :visible="inputModalParams.visible"
        :confirm-loading="inputModalParams.confirmLoading"
        :cancelText="inputModalParams.cancelText"
        :okText="inputModalParams.okText"
        :maskClosable="inputModalParams.maskClosable"
        :destroyOnClose="inputModalParams.destroyOnClose"
        :width="inputModalParams.width"
        @ok="inputModalParams.confirm"
        @cancel="inputModalParams.cancel"
      >
        <div>
          <a-form :form="form" v-bind="formItemLayout">
            <a-form-item label="签署人类型" has-feedback>
              <a-select
                @change="signTypeChange"
                v-decorator="[
                  'signerType',
                  { rules: [{ required: true, message: '请选择类型' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="(item, index) in inputModalParams.signerTypeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <!-- 个人 默认false -->
            <a-form-item label="签署方式" required>
              <!-- <a-switch v-model="form.isAutoSign" /> -->
              <a-radio-group
                v-decorator="[
                  'signMethod',
                  { valuePropName: 'checked', initialValue: '0' },
                ]"
                button-style="solid"
              >
                <a-radio-button :value="0"> 手动签署 </a-radio-button>
                <a-radio-button :value="1" :disabled="inputModalParams.switchDisabled"> 自动签署 </a-radio-button>
                <a-radio-button :value="2" :disabled="inputModalParams.switchDisabled"> 手动触发自动签署</a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="协议内容通用字段">
              <a-cascader
                v-decorator="[
                  'generalFieldsOfAgreementContentArr',
                  {
                    initialValue:
                      inputModalParams.generalFieldsOfAgreementContentInitVal,
                    rules: [
                      { type: 'array', required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="请选择"
                :options="inputModalParams.generalFieldsOfAgreementContentList"
              />
            </a-form-item>
            <a-form-item label="签署策略">
              <a-select
                v-decorator="[
                  'keywordSignStrategy',
                  { rules: [{ required: true, message: '请选择类型' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option value="0">所有关键字签章</a-select-option>
                <a-select-option value="1">第一个关键字签章</a-select-option>
                <a-select-option value="2">最后一个关键字签章</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="排序">
              <a-input
                v-decorator="[
                  'sort',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import Step from "./step";
import ReactTable from "@/components/react-table";

export default {
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      tableData: [],
      totalItems: 0,
      columns: [
        {
          title: this.l("签署类型"),
          dataIndex: "signTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signTypeName" },
        },
        {
          title: this.l("签署方式"),
          dataIndex: "signMethodDesc",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signMethodDesc" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        cancelText: "取消",
        okText: "确认",
        form: {
          // isAutoSign: false,
          // signerType: '',
          // generalFieldsOfAgreementContentName: '',
        },
        id: null,
        signerTypeList: [], // { name: "客户", value: "Customer" }
        generalFieldsOfAgreementContentList: [],
        generalFieldsOfAgreementContentInitVal: [],
        switchDisabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      actionsType: {},
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this._agreementSigningMethodGetList();
    this._generalFieldsOfAgreementContentGetList();
  },
  methods: {
    getSignMethodDesc(signMethod) {
      if (signMethod == 0) {
        return "手动签署";
      }
      if (signMethod == 1) {
        return "自动签署";
      }
      if (signMethod == 2) {
        return "手动触发自动签署";
      }
    },
    // get table list
    async _agreementSigningMethodGetList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementSigningMethod/GetList",
          params: { agreementTemplateId: this.$route.params.id },
        });
        // let res = await this.agreementSignMethodService.agreementSigningMethodGetList(
        //   this.params.agreementTemplateId
        // );
        // console.log(res)
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTypeName:
              item.signerType === "Organization"
                ? "组织架构"
                : item.signerType === "Customer"
                ? "客户"
                : "个人",
            signMethodDesc: this.getSignMethodDesc(item.signMethod),
            isAutoSignDesc: item.isAutoSign ? "是" : "否",
          };
        });
        this.totalItems = res.length;
        this.initSignerTypeList(this.tableData);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 只有客户可以添加多条
    async initSignerTypeList(arr) {
      this.inputModalParams.signerTypeList = [];
      // if (this.params.agreementType !== 'UnnaturalPerson') {
      //   this.inputModalParams.signerTypeList = [{ name: "客户", value: "Customer" }];
      // }
      let isHaveOrganization = false;
      let isHavePersonal = false;
      arr.map((item) => {
        if (item.signerType === "Organization") {
          isHaveOrganization = true;
        }
        if (item.signerType === "Personal") {
          isHavePersonal = true;
        }
      });
      // if (!isHaveOrganization) {
      this.inputModalParams.signerTypeList.push({
        name: "组织架构",
        value: "Organization",
      });
      //}
      let res = await this.commonService.get({
        url: "/api/services/app/AgreementTemplate/GetEditById",
        params: { id: this.$route.params.id },
      });
      console.info(res);
      if (!isHavePersonal && res.agreementGroupType != 1) {
        //证明类文件不需要个人签
        this.inputModalParams.signerTypeList.push({
          name: "个人",
          value: "Personal",
        });
      }
    },
    signTypeChange(value) {
      if (value === "Personal") {
        this.form.setFieldsValue({
          isAutoSign: false,
        });
        this.inputModalParams.switchDisabled = true;
      } else {
        this.inputModalParams.switchDisabled = false;
      }
    },
    // 修改
    editItem(id) {
      // if (!this.params.canEdit) {
      //   this.$message.info('协议模板在项目应用后，不可编辑签署方式')
      //   return
      // }
      // const { id } = newV.item;
      this._agreementSigningMethodGetEditById(id);
    },
    // get item
    async _agreementSigningMethodGetEditById(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementSigningMethod/GetEditById",
          params: { id: id },
        });
        // let res = await this.agreementSignMethodService.agreementSigningMethodGetEditById(
        //   id
        // );
        this.spinning = false;
        //console.log(res)
        this.createSignType(res);
      } catch (error) {
        this.spinning = false;
      }
    },
    // save
    async _agreementSigningMethodSave(opts) {
      // console.log(opts)
      this.inputModalParams.confirmLoading = true;
      try {
        // let res = await this.agreementSignMethodService.agreementSigningMethodSave(opts);
        let res = await this.commonService.post({
          url: "/api/services/app/AgreementSigningMethod/Save",
          params: { ...opts },
        });
        this.inputModalParams.confirmLoading = false;
        this.inputModalParams.visible = false;
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        this._agreementSigningMethodGetList();
      } catch (error) {
        this.inputModalParams.confirmLoading = false;
      }
    },
    createSignType(item) {
      let _this = this;
      if (item) {
        this.inputModalParams.title = "修改签署方式";
      } else {
        this.inputModalParams.title = "新增签署方式";
      }
      this.inputModalParams.okText = "确认";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            // console.log("Received values of form: ", values);
            let opts = await {
              agreementTemplateId: _this.$route.params.id,
              generalFieldsOfAgreementContentId:
                values.generalFieldsOfAgreementContentArr[1],
              isAutoSign: values.isAutoSign,
              signMethod: values.signMethod,
              signerType: values.signerType,
              sort: Number(values.sort),
              keywordSignStrategy: Number(values.keywordSignStrategy),
            };
            if (
              values.signerType !== "Organization" &&
              values.signerType !== "Customer" &&
              values.signerType !== "Personal"
            ) {
              opts.signerType = _this.getSignerType(values.signerType);
            }
            if (item) opts.id = item.id;
            // console.log(opts);
            _this._agreementSigningMethodSave(opts);
          }
        });
      };
      if (item) {
        let parentId;
        let parentName;
        let childName;
        this.inputModalParams.generalFieldsOfAgreementContentList.map(
          (it, index) => {
            it.children.map((t, i) => {
              if (t.value === item.generalFieldsOfAgreementContentId) {
                parentId = it.value;
                parentName = it.label;
                childName = t.label;
              }
            });
          }
        );
        this.inputModalParams.generalFieldsOfAgreementContentInitVal = [
          parentName,
          childName,
        ];
        this.$nextTick(() => {
          this.form.setFieldsValue({
            signerType:
              item.signerType === "Organization"
                ? "组织架构"
                : item.signerType === "Customer"
                ? "客户"
                : "个人",
            isAutoSign: item.isAutoSign,
            generalFieldsOfAgreementContentArr: [
              parentId,
              item.generalFieldsOfAgreementContentId,
            ],
            sort: item.sort,
            keywordSignStrategy: item.keywordSignStrategy.toString(),
          });
          if (item.signerType === "Personal")
            this.inputModalParams.switchDisabled = true;
        });
      }
      this.inputModalParams.visible = true;
    },
    async deleteItem(id) {
      // if (!this.params.canEdit) {
      //   this.$message.info('协议模板在项目应用后，不可删除签署方式')
      //   return
      // }
      this.spinning = true;
      try {
        // const res = await this.agreementSignMethodService.agreementSigningMethodDelete(
        //   id
        // );
        const res = await this.commonService.delete({
          url: "/api/services/app/AgreementSigningMethod/Delete",
          params: id,
        });
        this.spinning = false;
        this._agreementSigningMethodGetList();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
      } catch (error) {
        this.spinning = false;
      }
    },
    // get general fields
    async _generalFieldsOfAgreementContentGetList() {
      this.spinning = true;
      try {
        // let res = await this.generalFieldsService.generalFieldsOfAgreementContentGetListByTemplateId(
        //   this.params.agreementTemplateId
        // );
        let res = await this.commonService.get({
          url: "/api/services/app/GeneralFieldsOfAgreementContent/GetListByTemplate",
          params: { templateId: this.$route.params.id },
        });
        let data = res;
        this.inputModalParams.generalFieldsOfAgreementContentList =
          this.initCascaderData(data);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    getSignerType(s) {
      switch (s) {
        case "组织架构":
          return "Organization";
        case "客户":
          return "Customer";
        case "个人":
          return "Personal";
      }
    },
    initCascaderData(arr) {
      const keyMap = {
        groupName: "label",
        groupId: "value",
        showName: "label",
        id: "value",
      };
      arr.forEach((obj) => {
        for (let key in obj) {
          if (key === "children" && Array.isArray(obj[key])) {
            this.initCascaderData(obj[key]);
          } else {
            let newKey = keyMap[key];
            if (newKey) {
              obj[newKey] = obj[key];
              delete obj[key];
            }
          }
        }
      });
      return arr;
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "AgreementTemplete",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("编辑"),
              icon: "edit",
              fn: (data) => {
                _this.editItem(data.id);
              },
            },
          ],
          delete: {
            granted: true,
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    async _agreementTemplateAsyncElectronicSignService() {
      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url: "/api/services/app/AgreementTemplate/AsyncElectronicSignService",
          params: { id: this.$route.params.id },
        });
        // this.$notification["success"]({
        //   message: this.l("同步成功"),
        // });
        setTimeout(() => {
          this.goChooseSeal();
        }, 1000);
      } catch (error) {
        this.spinning = false;
      }
    },
    goChooseSeal() {
      this.$router.push({
        path: `/app/fs/unChooseSeal/${this.$route.params.id}/${this.$route.params.standard}`,
      });
    },
    goBack() {
      // this.$router.back()
      this.$router.push({
        path: `/app/fs/unAddTemplete/${this.$route.params.id}`,
      });
    },
  },
  components: {
    Step,
    ReactTable,
  },
};
</script>


<style scoped lang="less">
@import "../../../styles/common.less";
.sign-method {
  padding: 50px 100px;
  .t-w {
    background-color: @color-white;
  }
  .t-head {
    #flex;
    #flex-jcsb;
    margin-bottom: 15px;
    span:nth-of-type(1) {
      #fw;
      color: @color-black;
    }
  }
  .b-w {
    padding-top: 60px;
    #flex;
    #flex-hc;
    background-color: @color-white;
  }
}
</style>